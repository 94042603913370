import { useState } from "react";
import CategoriesPage from "./pages/ProfilePage/ProfilesPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  const [showProfContent, setShowProfContents] = useState(false);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<CategoriesPage />} />
        </Routes>{" "}
      </BrowserRouter>
    </div>
  );
}

export default App;
