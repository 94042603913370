import logo from "../../assets/logo3.png";
import axios from "axios";
import "./profilePageStyle.css";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
const CategoriesPage = () => {
  const [userData, setUserData] = useState(false);
  const [user_id] = useSearchParams();
  const getUserData = () => {
    let data = JSON.stringify({
      user_link: "click-tag.com/" + user_id?.get("q"),
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://camp-coding.tech/click_tag/user/tags/select_myprofile_by_url.php",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setUserData(response.data?.message);
        console.log(response?.data?.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const saveContactToDevice = async () => {
    try {
      const options = {
        method: "POST",
        body: JSON.stringify({
          name: userData?.full_name,
          phoneNumber: [
            { value: userData?.phones[0]?.phone_number, type: "work" },
          ],
          email: [{ value: userData?.phones[0]?.email, type: "work" }],
        }),
      };

      // Use the Contacts API to save the contact
      await navigator.contacts.save(
        {
          name: userData?.full_name,
          phoneNumber: [
            { value: userData?.mails[0]?.phone_number, type: "work" },
          ],
          email: [{ value: userData?.mails[0]?.email, type: "work" }],
        },
        options
      );

      alert("Contact saved successfully!");
    } catch (error) {
      console.log(navigator);
      console.error("Error saving contact:", error);
    }
  };
  const downloadContact = () => {
    let phoneNumbers = "";
    let emailAddresses = "";

    if (userData.phones && userData.phones.length > 0) {
      phoneNumbers = userData.phones
        .map((phone) => {
          return `TEL:${phone.phone_number}`;
        })
        .join("\n");
    }

    if (userData.mails && userData.mails.length > 0) {
      emailAddresses = userData.mails
        .map((mail) => {
          return `EMAIL:${mail.email}`;
        })
        .join("\n");
    }

    const vcardData = `BEGIN:VCARD
VERSION:3.0
FN:${userData?.full_name}
${phoneNumbers}
${emailAddresses}
END:VCARD`;

    const blob = new Blob([vcardData], { type: "text/vcard" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "contact.vcf");
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <div className="newProfileTage">
      {/* <div className="newProfileMenu"> */}
      {/* <img src={logo} className="clickTagLogo" /> */}
      {/* <div className="navTitle">My Profile</div> */}
      {/* </div> */}
      <div
        className="imageProfile"
        style={{
          background: `url(${userData?.cover_image})`,
        }}
      >
        {!userData?.cover_image || !userData.cover_image?.length ? (
          <img src={logo} alt="sd" />
        ) : null}
      </div>
      <div className="df">
        <div className="profileImage">
          <img src={userData?.profile_url} alt="" />
        </div>
        <div className="columnDiv">
          <h5>{userData?.full_name}</h5>
          <span>({userData?.title})</span>
          <span>({userData?.type})</span>
          <p>{userData?.bio}</p>
        </div>
      </div>

      <div className="additionalInformations">
        <div className="text textContainer">
          <button
            className="btn_dark"
            onClick={() => {
              axios.post(
                "https://camp-coding.tech/click_tag/user/tags/save_profile.php",
                {
                  ...{
                    profile_id: userData?.profile_id,
                  },
                }
              );

              downloadContact();
            }}
          >
            Save Contact
          </button>
          <div className="socialMedias">
            {userData?.social_accounts?.map((item) => {
              return (
                <div
                  className="profileBox"
                  onClick={() => {
                    axios.post(
                      "https://camp-coding.tech/click_tag/user/tags/add_click_social.php",
                      {
                        ...{
                          social_id: item?.social_id,
                          profile_id: userData?.profile_id,
                          title: item?.title,
                          image: item?.image_logo_id,
                        },
                      }
                    );
                    window.open(item?.url, "_blanck");
                  }}
                >
                  <img src={item?.image_logo_id} alt="" />
                  <span>{item?.title}</span>
                </div>
              );
            })}
          </div>
        </div>

        <div className="additional multiadditional">
          <div className="icon">
            <img
              src="https://cdn-icons-png.flaticon.com/512/2165/2165310.png"
              alt=""
            />
            <h3>Mails</h3>
          </div>

          <div className="text textContainer">
            <div className="socialMedias">
              {userData?.mails?.map((item) => {
                return (
                  <div
                    className="profileBox"
                    onClick={() => {
                      axios.post(
                        "https://camp-coding.tech/click_tag/user/tags/add_contact_click.php",
                        {
                          ...{
                            profile_id: userData?.profile_id,

                            contact_id: item?.mail_id,
                            type: "emails",
                          },
                        }
                      );
                      window.open("mailto:" + item?.email, "_blanck");
                    }}
                  >
                    <img
                      src={"https://openclipart.org/image/2000px/216531"}
                      alt=""
                    />
                    <a
                      style={{ color: "black", textDecoration: "none" }}
                      href={"mailto:" + item?.email}
                      target="_blanck"
                    >
                      {item?.email?.length > 13
                        ? item?.email?.substring(0, 13) + "..."
                        : item?.email}
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="additional multiadditional">
          <div className="icon">
            <img
              src="https://www.iconpacks.net/icons/1/free-phone-icon-1-thumb.png"
              alt=""
            />
            <h3>Phones</h3>
          </div>
          <div className="text textContainer">
            <div className="socialMedias">
              {userData?.phones?.map((item) => {
                return (
                  <div
                    className="profileBox"
                    onClick={() => {
                      axios.post(
                        "https://camp-coding.tech/click_tag/user/tags/add_contact_click.php",
                        {
                          ...{
                            profile_id: userData?.profile_id,

                            contact_id: item?.phone_id,
                            type: "phones",
                          },
                        }
                      );
                      window.open("tel:" + item?.phone_number, "_blanck");
                    }}
                  >
                    <img
                      src={
                        "https://www.sahabcosmetics.com/wp-content/uploads/2023/04/Phone-Icon-Vector-PNG-1.png"
                      }
                      alt=""
                    />

                    <a
                      style={{ color: "black", textDecoration: "none" }}
                      href={"tel:" + item?.phone_number}
                      target="_blanck"
                    >
                      {item?.phone_number?.length > 13
                        ? item?.phone_number?.substring(0, 13) + "..."
                        : item?.phone_number}
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesPage;
